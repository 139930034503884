<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            お問い合わせ管理
          </div>
        </div>
        <div class="row-input admin_center"></div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
          <button
            v-on:click="createInquiry()"
            class="float-right button-create-user"
          >
            フォームの編集
          </button>
        </div>
      </div>
      <!-- <div class="row p-3 d-flex">
        <div class="row-input px-3 w-50">
          <label class="col-sm-5 font-weight-bold pl-0">メールアドレス</label>
          <input class="col-sm-12 form-control" v-model="inquiry.email" />
        </div>
      </div>
      <div class="row px-3 pb-3 d-flex">
        <div class="row-input px-3 w-50">
          <label class="col-sm-5 font-weight-bold pl-0">氏名</label>
          <input class="form-control col-sm-12" v-model="inquiry.name" />
        </div>
      </div> -->
      <div class="row px-3 d-flex">
        <div class="cpx-15 col-6">
          <input
            class="form-control w-100"
            v-model="inquiry.keywords"
            placeholder="検索"
            v-on:keyup.enter="getListInquiries(1)"
          />
        </div>
        <div class="col-6 row d-flex align-items-center">
          <div class="col-3 text-center p-0">
            <label class="font-weight-bold p-0">お問い合わせ日時</label>
          </div>
          <div class="col-4 p-0 row-input">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="inquiry.fromTimeInquiry"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListInquiries(1)"
              ></date-picker>
            </div>
          </div>
          <div class="col-1 p-0 text-center">
            <span class="custom-span" style="font-size: 20px">~</span>
          </div>
          <div class="col-4 row-input">
            <div class="fd-admin-listproperty-card-filter filter-date">
              <date-picker
                name="date"
                v-model="inquiry.toTimeInquiry"
                :config="optionsDate"
                autocomplete="off"
                placeholder="日付を選択"
                @input="getListInquiries(1)"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row px-3 d-flex">
        <div class="row-input px-3 w-50">
          <label class="col-sm-5 font-weight-bold pl-0">ステータス</label>
          <b-form-select
            class="form-input col-sm-12"
            v-model="inquiry.status"
            :options="optionsStatus"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getListInquiries(1)"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div> -->
      <Tables
        :items="listInquiry.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-15"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{
              (listInquiry.current_page - 1) * listInquiry.per_page + index + 1
            }}
          </td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex">
            <div class="w-5">
              <router-link
                :to="{
                  name: $route.params.shopId
                    ? 'detail inquiry'
                    : 'detail inquiry domain',
                  params: {
                    index: index,
                    page: page,
                  },
                  query: {
                    NO:
                      (listInquiry.current_page - 1) * listInquiry.per_page +
                      index +
                      1,
                  },
                }"
                class="d-flex flex-column align-items-center"
              >
                <CButton
                  class="mx-1 btn-other"
                  color="primary"
                  square
                  variant="outline"
                  size="sm"
                  v-b-modal.modal-comfirm
                >
                  確認
                </CButton>
              </router-link>
            </div>
            <div>
              <CButton
                class="mx-1 btn-del"
                color="danger"
                square
                v-on:click="deleteInquiries(item.id)"
                variant="outline"
                size="sm"
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listInquiry.total"
      >
        <p class="font-weight-bold">
          {{ listInquiry.total }}件中{{ listInquiry.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listInquiry.last_page"
      >
        <CPagination
          v-if="listInquiry.total"
          :activePage.sync="page"
          :pages="listInquiry.last_page"
          align="center"
        />
        <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-inquiry" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="deleteInquiry(inquiry.id)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-inquiry')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
// import { Constants } from "../../utils/constants";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListNew",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      inquiry: {
        id: "",
        email: "",
        name: "",
        fromTimeInquiry: "",
        toTimeInquiry: "",
        status: "",
        keywords: "",
      },
      optionsStatus: [
        {
          text: "未返信",
          value: 0,
        },
        {
          text: "返信済",
          value: 1,
        },
      ],
      fields: tableFields.QA,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      optionsDate: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListInquiries(1);
  },
  computed: {
    ...mapGetters(["listInquiry", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.$bvModal.hide("delete-modal-inquiry");
        this.getListInquiries(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listInquiry() {
      this.page = this.listInquiry.current_page;
    },
    page() {
      this.getListInquiries(this.page);
    },
  },
  methods: {
    ...mapActions({ getListInquiry: "getListInquiry" }),
    ...mapActions({ deleteInquiry: "deleteInquiry" }),
    createInquiry() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "create inquiry",
          params: { shopId: this.shop_id, page: this.page },
          query: {
            total: this.listInquiry.total ? this.listInquiry.total + 1 : 1,
          },
        });
      } else {
        this.$router.push({
          name: "create inquiry domain",
          params: { page: this.page },
          query: {
            total: this.listInquiry.total ? this.listInquiry.total + 1 : 1,
          },
        });
      }
    },
    getListInquiries(page) {
      const time_current = new Date();
      if (
        new Date(this.inquiry.fromTimeInquiry) > time_current ||
        new Date(this.inquiry.toTimeInquiry) > time_current
      ) {
        this.$toasted.error(
          "選択した時間は現在の時間より長くすることはできません"
        );
      } else if (
        this.inquiry.fromTimeInquiry &&
        this.inquiry.toTimeInquiry &&
        this.inquiry.fromTimeInquiry > this.inquiry.toTimeInquiry
      ) {
        this.$toasted.error("該当日時の検索はできません。");
      } else {
        if (page === undefined) {
          page = this.page;
        }
        const formData = {
          page: page,
          data: {
            shop_id: this.shop_id,
            keywords: this.inquiry.keywords,
            // email: this.inquiry.email,
            // name: this.inquiry.name,
            // status: this.inquiry.status,
            update_start_date: this.inquiry.fromTimeInquiry,
            update_end_date: this.inquiry.toTimeInquiry,
            limit: this.limit,
          },
        };
        this.getListInquiry(formData);
      }
    },
    changeLimitPage() {
      this.getListInquiries(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    resetFieldSearch() {
      this.inquiry.email = "";
      this.inquiry.name = "";
      this.inquiry.fromTimeInquiry = "";
      this.inquiry.toTimeInquiry = "";
      this.inquiry.status = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          email: this.inquiry.email,
          name: this.inquiry.name,
          status: this.inquiry.status,
          update_start_date: this.inquiry.fromTimeInquiry,
          update_end_date: this.inquiry.toTimeInquiry,
          limit: this.limit,
        },
      };
      this.getListInquiry(formData);
    },
    deleteInquiries(id) {
      this.inquiry.id = id;
      this.$bvModal.show("delete-modal-inquiry");
    },
  },
};
</script>
